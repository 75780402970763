import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL, IMG_URL } from "../../AdminComponent/BaseUrl";
import { Helmet } from "react-helmet";
import useBreadcrumb from "../../Utils/Breadcrum";

const Terms = () => {
  const [data, setData] = useState([]);
  const [metadata, setMeta] = useState([]);

  const breaddata = useBreadcrumb();

  async function getmetadetail_terms() {
    axios.get(`${BASE_URL}/getfaq`, data).then((res) => {
      setData(res.data);
    });
  }

  async function getmetadetail() {
    const data = {
      page_id: 15,
    };
    axios.post(`${BASE_URL}/getmetadetail`, data).then((res) => {
      setMeta(res.data[0]);
    });
  }

  useEffect(() => {
    getmetadetail();
  }, []);

  return (
    <div>
      <div id="site-main" class="site-main">
        <Helmet>
          <title>{metadata.seo_title}</title>
          <meta
            name="description"
            content={metadata.seo_desc}
            dangerouslySetInnerHTML={{ __html: metadata.seo_desc }}
          />
          <meta name="author" content={metadata.seo_title} />
        </Helmet>
        <div id="main-content" class="main-content">
          <div id="primary" class="content-area">
            <div
              id="title"
              className="page-title"
              style={{
                backgroundImage: `url('${IMG_URL}/Breadcrumbs/${breaddata.upload_image}')`,
              }}
            >
              <div class="section-container">
                <div class="content-title-heading">
                  <h1 class="text-title-heading">Terms & Condition</h1>
                </div>
                <div class="breadcrumbs">
                  <a href="index.html">Home</a>
                  <span class="delimiter"></span>Terms & Condition
                </div>
              </div>
            </div>

            <div id="content" class="site-content" role="main">
              <div class="page-contact">
                <section class="section section-padding m-b-70">
                  <div class="section-container">
                    <div class="">
                      <div class="block-widget-wrap">
                        <div class="">
                          <h2 class="text-center" style={{ fontFamily: 'Arial, sans-serif', fontSize: '2.5rem', color: '#343a40' }}>
                            Terms & Conditions / Terms of Use
                          </h2>

                          {/* <!-- Section A - F --> */}
                          <div class="mt-4">
                            <p>
                              <strong>A.</strong> This document is an electronic
                              record in terms of the Information Technology Act,
                              2000 and rules thereunder as applicable and the
                              amended provisions pertaining to electronic
                              records in various statutes as amended by the
                              Information Technology Act, 2000. This electronic
                              record is generated by a computer system and does
                              not require any physical or digital signatures.
                            </p>
                            <p>
                              <strong>B.</strong> This document is published in
                              accordance with the provisions of Rule 3 (1) of
                              the Information Technology (Intermediaries
                              guidelines) Rules, 2011 that require publishing
                              the rules and regulations, privacy policy, and
                              Terms of Use for access or usage of domain name
                              micasasucasa.in (“Website”), including the related
                              mobile site and mobile application (hereinafter
                              referred to as “Platform”).
                            </p>
                            <p>
                              <strong>C.</strong> The Platform is owned by ARCH
                              INTERNATIONAL, a company incorporated under the
                              Companies Act, 1956 with its registered office at
                              Building No./Flat No.: Shop Number 1, 1223/61
                              Road/Street: Link Road Nearby Landmark: Opposite
                              Aditya Bakery, Linkroad Locality/Sub Locality:
                              Tikari, PIN Code: 460001 (hereinafter referred to
                              as "Platform Owner", “we”, “us”, “our”).
                            </p>
                            <p>
                              <strong>D.</strong> Your use of the Platform and
                              services and tools are governed by the following
                              terms and conditions ("Terms of Use") as
                              applicable to the Platform including the
                              applicable policies which are incorporated herein
                              by way of reference. If You transact on the
                              Platform, You shall be subject to the policies
                              that are applicable to the Platform for such
                              transaction. By mere use of the Platform, You
                              shall be contracting with the Platform Owner and
                              these terms and conditions including the policies
                              constitute Your binding obligations, with Platform
                              Owner. These Terms of Use relate to your use of
                              our website, goods (as applicable) or services (as
                              applicable) (collectively, “Services”). Any terms
                              and conditions proposed by You which are in
                              addition to or which conflict with these Terms of
                              Use are expressly rejected by the Platform Owner
                              and shall be of no force or effect. These Terms of
                              Use can be modified at any time without assigning
                              any reason. It is your responsibility to
                              periodically review these Terms of Use to stay
                              informed of updates.
                            </p>
                            <p>
                              <strong>E.</strong> For the purpose of these Terms
                              of Use, wherever the context so requires "you",
                              “your” or "user" shall mean any natural or legal
                              person who has agreed to become a user/buyer on
                              the Platform.
                            </p>
                            <p>
                              <strong>F.</strong> ACCESSING, BROWSING OR
                              OTHERWISE USING THE PLATFORM INDICATES YOUR
                              AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER
                              THESE TERMS OF USE, SO PLEASE READ THE TERMS OF
                              USE CAREFULLY BEFORE PROCEEDING.
                            </p>
                          </div>

                          {/* <!-- Section G --> */}
                          <div class="mt-4">
                            <h3 class="section-title">
                              G. The use of Platform and/or availing of our
                              Services is subject to the following Terms of Use:
                            </h3>
                            <ul>
                              <li>
                                <strong>I.</strong> To access and use the
                                Services, you agree to provide true, accurate
                                and complete information to us during and after
                                registration, and you shall be responsible for
                                all acts done through the use of your registered
                                account on the Platform.
                              </li>
                              <li>
                                <strong>II.</strong> Neither we nor any third
                                parties provide any warranty or guarantee as to
                                the accuracy, timeliness, performance,
                                completeness or suitability of the information
                                and materials offered on this website or through
                                the Services, for any specific purpose. You
                                acknowledge that such information and materials
                                may contain inaccuracies or errors and we
                                expressly exclude liability for any such
                                inaccuracies or errors to the fullest extent
                                permitted by law.
                              </li>
                              <li>
                                <strong>III.</strong> Your use of our Services
                                and the Platform is solely and entirely at your
                                own risk and discretion for which we shall not
                                be liable to you in any manner. You are required
                                to independently assess and ensure that the
                                Services meet your requirements.
                              </li>
                              <li>
                                <strong>IV.</strong> The contents of the
                                Platform and the Services are proprietary to us
                                and are licensed to us. You will not have any
                                authority to claim any intellectual property
                                rights, title, or interest in its contents. The
                                contents includes and is not limited to the
                                design, layout, look and graphics.
                              </li>
                              <li>
                                <strong>V.</strong> You acknowledge that
                                unauthorized use of the Platform and/or the
                                Services may lead to action against you as per
                                these Terms of Use and/or applicable laws.
                              </li>
                              <li>
                                <strong>VI.</strong> You agree to pay us the
                                charges associated with availing the Services.
                              </li>
                              <li>
                                <strong>VII.</strong> You agree not to use the
                                Platform and/ or Services for any purpose that
                                is unlawful, illegal or forbidden by these
                                Terms, or Indian or local laws that might apply
                                to you.
                              </li>
                              <li>
                                <strong>VIII.</strong> You agree and acknowledge
                                that website and the Services may contain links
                                to other third party websites. On accessing
                                these links, you will be governed by the terms
                                of use, privacy policy and such other policies
                                of such third party websites.
                              </li>
                              <li>
                                <strong>IX.</strong> You understand that upon
                                initiating a transaction for availing the
                                Services you are entering into a legally binding
                                and enforceable contract with the Platform Owner
                                for the Services.
                              </li>
                              <li>
                                <strong>X.</strong> You shall indemnify and hold
                                harmless Platform Owner, its affiliates, group
                                companies (as applicable) and their respective
                                officers, directors, agents, and employees, from
                                any claim or demand, or actions including
                                reasonable attorneys’ fees, made by any third
                                party or penalty imposed due to or arising out
                                of Your breach of this Terms of Use, privacy
                                Policy and other Policies, or Your violation of
                                any law, rules or regulations or the rights
                                (including infringement of intellectual property
                                rights) of a third party.
                              </li>
                              <li>
                                <strong>XI.</strong> In no event will the
                                Platform Owner be liable for any indirect,
                                consequential, incidental, special or punitive
                                damages, including without limitation damages
                                for loss of profits or revenues, business
                                interruption, loss of business opportunities,
                                loss of data or loss of other economic
                                interests, whether in contract, negligence, tort
                                or otherwise, arising from the use of or
                                inability to use the Services.
                              </li>
                              <li>
                                <strong>XII.</strong> Notwithstanding anything
                                contained in these Terms of Use, the parties
                                shall not be liable for any failure to perform
                                an obligation under these Terms if performance
                                is prevented or delayed by a force majeure
                                event.
                              </li>
                              <li>
                                <strong>XIII.</strong> These Terms and any
                                dispute or claim relating to it, or its
                                enforceability, shall be governed by and
                                construed in accordance with the laws of India.
                              </li>
                              <li>
                                <strong>XIV.</strong> All disputes arising out
                                of or in connection with these Terms shall be
                                subject to the exclusive jurisdiction of the
                                courts in Raipur, Chattisgarh.
                              </li>
                              <li>
                                <strong>XV.</strong> All concerns or
                                communications relating to these Terms must be
                                communicated to us using the contact information
                                provided on this website.
                              </li>
                            </ul>
                          </div>
                          <div class="mt-4">
                            <h3 class="section-title text-muted">
                              Please read these terms and conditions carefully
                              before using our services. By accessing or using
                              our website and purchasing products from us, you
                              agree to comply with and be bound by the following
                              terms and conditions.
                            </h3>
                          </div>
                          {/* <!-- Section 1. General --> */}
                          <div class="mt-4">
                            <h3 class="section-title">1. General</h3>
                            <p>
                              These Terms and Conditions govern the use of this
                              Service and the agreement that operates between
                              the individual accessing or using the Service, or
                              the company, or other legal entity on behalf of
                              which such individual is accessing or using the
                              Service, as applicable (Hereinafter “You”) and the
                              [website name/ company name].
                              <br />
                              Your access to and use of the Service is
                              conditioned on Your acceptance of and compliance
                              with these Terms and Conditions. These Terms and
                              Conditions apply to all visitors, users and others
                              who access or use the Service.
                              <br />
                              By accessing or using the Service You agree to be
                              bound by these Terms and Conditions. If You
                              disagree with any part of these Terms and
                              Conditions then You may not access the Service.
                              <br />
                              You represent that you are over the age of 18. The
                              Company does not permit those under 18 to use the
                              Service.
                              <br />
                              Your access to and use of the Service is also
                              conditioned on Your acceptance of and compliance
                              with the Privacy Policy of the Company. Our
                              Privacy Policy describes Our policies and
                              procedures on the collection, use and disclosure
                              of Your personal information when You use the
                              Application or the Website and tells You about
                              Your privacy rights and how the law protects You.
                              Please read Our Privacy Policy carefully before
                              using Our Service.
                            </p>
                          </div>

                          {/* <!-- Section 2. Payment Guidelines --> */}
                          <div class="mt-4">
                            <h3 class="section-title">2. Payment Guidelines</h3>
                            <ul>
                              <li>
                                <strong>Payment Methods:</strong> We accept
                                major credit cards, debit cards, and payment
                                through third-party services such as Gpay,
                                Paytm, etc. Payment must be completed at the
                                time of purchase.
                              </li>
                              <li>
                                <strong>Pricing:</strong> All prices are listed
                                in [currency] and include any applicable taxes
                                unless stated otherwise. Prices are subject to
                                change without notice, but any changes will not
                                affect orders that have already been confirmed.
                              </li>
                              <li>
                                <strong>Payment Security:</strong> We use secure
                                payment gateways to ensure that your payment
                                information is protected. We do not store your
                                credit card details.
                              </li>
                              <li>
                                <strong>Failed Transactions:</strong> : If your
                                payment is declined, you will receive a
                                notification, and your order will not be
                                processed until full payment is received.
                              </li>
                              <li>
                                <strong>Refunds and Returns:</strong> Please
                                refer to our <b>Returns and Refund Policy</b>{" "}
                                for details regarding returns, exchanges, and
                                refunds.
                              </li>
                            </ul>
                          </div>

                          {/* <!-- Section 3. Order Acceptance and Cancellation --> */}
                          <div class="mt-4">
                            <h3 class="section-title">
                              3. Order Acceptance and Cancellation
                            </h3>
                            <ul>
                              <li>
                                <strong>Order Confirmation:</strong> Your order
                                will be confirmed once payment is successfully
                                processed. You will receive an email
                                confirmation of your purchase.
                              </li>
                              <li>
                                <strong>Right to Cancel:</strong> [Furniture
                                Website Name] reserves the right to cancel any
                                order for reasons including stock availability,
                                suspicion of fraudulent transactions, or other
                                reasons. You will be notified, and any payment
                                will be refunded.
                              </li>
                            </ul>
                          </div>

                          {/* <!-- Section 4. Delivery Terms --> */}
                          <div class="mt-4">
                            <h3 class="section-title">4. Delivery Terms</h3>
                            <ul>
                              <li>
                                <strong>Shipping:</strong> We offer standard and
                                expedited shipping options. Delivery times may
                                vary depending on your location and the shipping
                                method chosen and if it is a custom made
                                product.
                              </li>
                              <li>
                                <strong>Delivery Issues:</strong> We are not
                                responsible for delays caused by shipping
                                carriers. However, if your product is damaged
                                during transit, please contact us immediately to
                                resolve the issue.
                              </li>
                              <li>
                                <strong>International Shipping:</strong> Duties,
                                customs, and other import taxes for
                                international orders are the responsibility of
                                the buyer.
                              </li>
                            </ul>
                          </div>

                          {/* <!-- Section 5. Privacy Policy --> */}
                          <div class="mt-4">
                            <h3 class="section-title">5. Privacy Policy</h3>
                            <ul>
                              <li>
                                <strong>Data Collection:</strong> We collect
                                personal information such as your name, address,
                                email, and payment details when you make a
                                purchase. We may also collect information about
                                your browsing habits on our website using
                                cookies.
                              </li>
                              <li>
                                <strong>Use of Data:</strong> We use your
                                personal information to process orders,
                                communicate with you, and improve our services.
                                We do not share your personal information with
                                third parties except for those necessary to
                                process your orders (e.g., payment processors,
                                shipping companies).
                              </li>
                              <li>
                                <strong>Data Security:</strong> We take
                                reasonable measures to protect your personal
                                information. However, no system can be
                                completely secure, and we cannot guarantee the
                                security of your data.
                              </li>
                              <li>
                                <strong>Your Rights:</strong> You have the right
                                to access, update, or request the deletion of
                                your personal information at any time. To do so,
                                please contact us via our [email/contact page].
                              </li>
                              <li>
                                <strong>Cookies:</strong> Our website uses
                                cookies to enhance your browsing experience. You
                                can choose to disable cookies in your browser
                                settings, but doing so may affect your ability
                                to use certain features of the website.
                              </li>
                            </ul>
                          </div>

                          {/* <!-- Section 6. Warranties and Limitation of Liability --> */}
                          <div class="mt-4">
                            <h3 class="section-title">
                              6. Warranties and Limitation of Liability
                            </h3>
                            <ul>
                              <li>
                                <strong>Product Warranties:</strong> The vendor
                                provides warranties for the goods, if
                                applicable, which will be communicated at the
                                time of purchase. Mi Casa Su Casa does not offer
                                any additional warranties unless otherwise
                                specified.
                              </li>
                              <li>
                                <strong>Limitation of Liability:</strong> To the
                                fullest extent permitted by law, Mi Casa Su Casa
                                and its vendors shall not be liable for any
                                indirect, incidental, or consequential damages
                                arising from the use of this website or the
                                purchase of any goods.
                              </li>
                              <li>
                                <strong>Force Majeure:</strong>We shall not be
                                responsible for delays or failures in
                                performance caused by events beyond our
                                reasonable control, including but not limited to
                                natural disasters, strikes, or governmental
                                actions.
                              </li>
                            </ul>
                          </div>

                          {/* <!-- Section 7. Amendments --> */}
                          <div class="mt-4">
                            <h3 class="section-title">7. Amendments</h3>
                            <p>
                              We reserve the right to modify these terms and
                              conditions at any time without prior notice.
                              Changes will be effective immediately upon posting
                              on the website. Your continued use of the website
                              after any changes signifies your acceptance of the
                              modified terms.
                            </p>
                          </div>

                          {/* <!-- Section 8. Contact Information --> */}
                          <div class="mt-4">
                            <h3 class="section-title">
                              8. Contact Information
                            </h3>
                            <p>
                              For questions regarding these Terms and
                              Conditions, payment, or your order, please contact
                              us at:
                            </p>
                            <p>
                              <strong>Email:</strong> info@micasasucasa.in
                            </p>
                            <p>
                              <strong>Phone:</strong> +918989000066
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
